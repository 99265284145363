/*
 * Essential Imports
 */

import "picturefill";
import "lazysizes";
import "./vendor/ls.object-fit";

/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
    document.documentElement.classList.remove("preload");
    document.documentElement.classList.remove("no-js");
}, 300);

let resizeId;
window.addEventListener("resize", function () {
    clearTimeout(resizeId);
    document.documentElement.classList.add("preload");
    resizeId = setTimeout(function () {
        document.documentElement.classList.remove("preload");
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/*
 * StickyHeader
 */

import StickyHeader from "./functions/sticky-header";
const sticky_header = new StickyHeader();

/*
 * Fancybox
 */

import { Fancybox } from "@fancyapps/ui";
Fancybox.bind("[data-fancybox]", {
    // Your options go here
});

/*
 * GA Events
 */

import "./functions/ga-events";
